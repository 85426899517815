<app-page-banner-item
  [imgUrl]="pageBannerData.imgUrl"
  [text]="pageBannerData.text"
  [title]="pageBannerData.title"></app-page-banner-item>

<section class="content-container text-start mt-30 px-4 md:px-0">
  <p
    class="text-main-text text-gray-dark"
    [innerHTML]="needHelpMedicationPageData.mainSectionText"></p>

  <div class="grid mt-10">
    <app-table-of-content
      class="flex mx-auto w-full px-4 md:px-0"
      [links]="navLinks"></app-table-of-content>
  </div>
</section>

<section
  *ngIf="needHelpMedicationPageData && navLinks.length; else loader"
  [id]="navLinks[0].id"
  class="content-container px-4 md:px-0">
  <div>
    <ng-container>
      <div
        id="{{ needHelpMedicationPageData.helpWithMedicine[0].id }}"
        class="mt-30">
        <h2
          class="text-h2-heading text-primary-base uppercase mb-5"
          [innerHTML]="
            needHelpMedicationPageData.helpWithMedicine[0].title
          "></h2>
        <div
          class="flex flex-col mb-10"
          [innerHTML]="
            needHelpMedicationPageData.helpWithMedicine[0].text
          "></div>
        <div
          class="md:w-55 w-full flex"
          *ngIf="needHelpMedicationPageData.helpWithMedicine[0].link.length">
          <a
            [href]="needHelpMedicationPageData.helpWithMedicine[0].link"
            target="_blank"
            class="flex justify-center w-full text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer"
            ><span class="text-main-text font-semibold"
              >Залишити заявку</span
            ></a
          >
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="arrayListOfMedicine.length; else loader">
    <app-carousel
      *ngIf="showCarousel"
      class="mt-15 flex"
      (changeSlide)="onChangeSlide($event)">
      <app-carousel-slide *ngFor="let ListOfMedicine of arrayListOfMedicine">
        <img
          class="rounded-3xl max-w-none w-82 h-82 md:w-102.5 md:h-102.5 xl:w-145 xl:h-145"
          src="{{ ListOfMedicine.imageUrl }}" />
      </app-carousel-slide>
    </app-carousel>

    <div class="mt-15 flex justify-between" *ngIf="!showCarousel">
      <div *ngFor="let ListOfMedicine of arrayListOfMedicine">
        <img
          class="rounded-3xl max-w-none w-82 h-82 md:w-102.5 md:h-102.5 xl:w-145 xl:h-145"
          src="{{ ListOfMedicine.imageUrl }}" />
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="needHelpMedicationPageData && navLinks.length; else loader">
    <div [id]="navLinks[1].id">
      <ng-container>
        <div class="mt-30">
          <h2
            [innerHTML]="needHelpMedicationPageData.helpWithMedicine[1].title"
            class="text-h2-heading text-primary-base uppercase mt-30 mb-5"></h2>
          <div
            class="flex flex-col mb-10"
            [innerHTML]="
              needHelpMedicationPageData.helpWithMedicine[1].text
            "></div>
          <div
            class="md:w-55 w-full flex"
            *ngIf="needHelpMedicationPageData.helpWithMedicine[1].link.length">
            <a
              [href]="needHelpMedicationPageData.helpWithMedicine[1].link"
              target="_blank"
              class="flex justify-center w-full text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer"
              ><span class="text-main-text font-semibold"
                >Залишити заявку</span
              ></a
            >
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <app-image-slider
    *ngIf="peopleGallery.length"
    [arrayListImage]="peopleGallery"></app-image-slider>

  <div class="md:px-0" *ngIf="textCarYoutube">
    <h3
      class="text-h3-subheading text-primary-base mt-30 mb-5"
      [innerHTML]="textCarYoutube.title"></h3>
    <div
      *ngIf="textCarYoutube.bodyText.length"
      [innerHTML]="textCarYoutube.bodyText"></div>
    <div *ngIf="videoLink">
      <iframe
        width="560"
        height="315"
        [src]="videoLink"
        class="rounded-30 w-[327px] md:w-210 xl:w-295 h-[184px] md:h-[473px] xl:h-166.25 mb-20"
        title="{{ textCarYoutube.title }}"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen></iframe>
    </div>
  </div>
</section>
<div class="py-30 mt-5 bg-primary-base">
  <app-info-cards
    [cards]="needHelpMedicationPageData.helpWithMedicineCards"></app-info-cards>
</div>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
