<a
  #cardWidth
  (mouseenter)="hovered = true"
  (mouseleave)="hovered = false"
  class="group flex flex-col w-82 md:w-95 md:h-87 p-5 md:p-10 mb-2 border-[3px] border-gray-stroke rounded-30 odd:mr-5 hover:cursor-pointer hover:border-secondary-base"
  href="{{ card.linkTo }}"
  target="_blank">
  <div class="w-75 h-10 object-contain mb-5">
    <img alt="whiteBit" src="{{ card.logo }}" />
  </div>
  <div
    class="w-72 md:w-75 h-26.5 mb-1 md:mb-12 text-gray-dark text-smaller-main md:text-main-text">
    {{ card.description }}
  </div>
  <div>
    <div
      [ngClass]="{
        'text-secondary-base border-secondary-base': hovered,
        'text-accent-base border-accent-base': !hovered
      }"
      class="flex justify-center w-full py-2 border-2 bg-transparent text-accent-base hover:text-secondary-base active:text-secondary-additional disabled:text-gray-gray2 rounded-10 border-accent-base duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer">
      <div class="text-main-text font-semibold flex flex-row justify-center">
        <div>{{ 'Допомогти' }}</div>
      </div>
    </div>
  </div>
</a>
