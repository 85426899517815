<app-page-banner-item [pageName]="pageName"></app-page-banner-item>
<section
  class="grid mt-30 px-2 xl:px-0 sm:px-10"
  *ngIf="!isLoading; else loader">
  <app-table-of-content
    class="flex mx-auto w-full"
    [links]="links"></app-table-of-content>
</section>

<div
  class="mx-auto content-container text-start px-4 xl:px-0 my-30 sm:px-10"
  *ngIf="!isLoading; else loader">
  <div>
    <img
      alt="{{ pageData[0].title }}"
      class="rounded-3xl mb-5 md:ml-5 sm:pr-10 md:px-0 w-full md:w-[410px] md:h-[282px] xl:w-[580px] xl:h-[400px] object-cover float-right"
      src="{{ pageData[0].imageURL }}" />
    <h2
      id="{{ pageData[0].key }}"
      class="md:text-h2-heading text-h2-360 uppercase text-primary-base mb-5">
      {{ pageData[0].title }}
    </h2>
    <div
      class="md:text-main-text text-smaller-main text-gray-dark mb-5"
      appPhoneLink
      [innerHTML]="pageData[0].bodyText"></div>
  </div>

  <div class="mx-auto content-container text-start my-30">
    <div>
      <img
        alt="{{ pageData[1].title }}"
        class="rounded-3xl mb-5 md:mr-5 sm:pr-10 md:px-0 w-full md:w-[410px] md:h-[282px] xl:w-[580px] xl:h-[400px] object-cover float-left"
        src="{{ pageData[1].imageURL }}" />

      <h2
        id="{{ pageData[1].key }}"
        class="md:text-h2-heading text-h2-360 uppercase text-primary-base md:mt-0 mt-10 mb-5">
        {{ pageData[1].title }}
      </h2>
      <div
        class="md:text-main-text text-smaller-main text-gray-dark mb-5"
        appPhoneLink
        [innerHTML]="pageData[1].bodyText"></div>
    </div>
  </div>
</div>

<div class="" *ngIf="!isLoading; else loader">
  <div class="bg-gray-card">
    <div
      class="container pt-30 pb-23 mx-auto content-container text-start px-4 md:px-0 sm:px-10">
      <h2
        id="{{ pageData[2].key }}"
        class="md:text-h2-heading text-h2-360 uppercase text-primary-base mb-5 md:w-145">
        {{ pageData[2].title }}
      </h2>
      <div
        class="md:text-main-text text-smaller-main text-gray-dark mb-7"
        appPhoneLink
        [innerHtml]="pageData[2].bodyText"></div>
    </div>
  </div>

  <div class="mx-auto content-container text-start px-4 md:px-0 py-30 sm:px-10">
    <h2
      id="{{ pageData[3].key }}"
      class="md:text-h2-heading text-h2-360 uppercase text-primary-base mb-5">
      {{ pageData[3].title }}
    </h2>
    <div
      class="md:text-main-text text-smaller-main text-gray-dark mb-15"
      appPhoneLink
      [innerHtml]="pageData[3].bodyText"></div>
    <app-link-button
      [routerLink]="contactsLink"
      class="sm:w-55 w-full flex justify-center items-center mx-auto"
      [title]="'Зв’язатися з фондом'"
      [linkButtonType]="'Primary'"
      [isHovered]="isHovered">
    </app-link-button>
  </div>
</div>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
