import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { BreakpointObserverService } from 'src/app/services/breakpoint-observer.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
})
export class SliderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  SlidesArray!: any;
  @Input()
  slide: boolean = false;

  typeCard!: boolean;
  widthCard!: number;
  totalCards!: number;
  currentPage: number = 1;
  pagePosition: string = '0%';
  cardsPerPage!: number;
  totalPages!: number;
  overflowWidth!: string;
  cardWidth!: string;
  isMobile: boolean = false;

  private amountOfSlides = 0;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input()
  title!: string;
  @ViewChild('container', { static: true, read: ElementRef })
  container!: ElementRef;

  constructor(private breakpointObserverService: BreakpointObserverService) {}

  @HostListener('window:resize') windowResize() {
    let newCardsPerPage = this.getCardsPerPage();
    if (newCardsPerPage != this.cardsPerPage) {
      this.cardsPerPage = newCardsPerPage;
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
        this.populatePagePosition();
      }
    }
  }

  ngOnInit() {
    this.breakpointObserverService
      .isMobile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile: boolean) => (this.isMobile = isMobile));

    this.setAmountOfDisplayedSlidesDependsOnScreenWidth();
  }

  public ngAfterViewInit() {
    if (this.SlidesArray[0][0] === 'main') {
      this.typeCard = true;
      this.widthCard = 580;
    }
    if (this.SlidesArray[0].type === 'alternative') {
      this.typeCard = false;
      this.widthCard = 380;
    }
    this.totalPages = this.amountOfSlides;
    this.cardsPerPage = this.getCardsPerPage();
  }

  getCardsPerPage() {
    return Math.floor(
      this.container.nativeElement.offsetWidth / this.widthCard,
    );
  }

  changePage(incrementor: number) {
    this.currentPage += incrementor;
    this.populatePagePosition();
  }

  populatePagePosition() {
    // Works on screen 1280+
    this.pagePosition = `calc(${-50 * (this.currentPage - 1)}% - ${
      5 * (this.currentPage - 1)
    }px)`;

    if (this.isMobile) {
      // // Works on screen 360
      this.pagePosition = `calc(${-103.5 * (this.currentPage - 1)}% - ${
        5 * (this.currentPage - 1)
      }px)`;
    }

    this.breakpointObserverService
      .isLaptop()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLaptop: boolean) => {
        if (isLaptop) {
          // Works on screen 960
          this.pagePosition = `calc(${-101.3 * (this.currentPage - 1)}% - ${
            5 * (this.currentPage - 1)
          }px)`;
        }
      });
  }

  private setAmountOfDisplayedSlidesDependsOnScreenWidth(): void {
    this.breakpointObserverService
      .isXXLScreen()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isXXLScreen: boolean) => {
        if (isXXLScreen) {
          this.amountOfSlides = this.SlidesArray.length - 1;
        } else {
          this.amountOfSlides = this.SlidesArray.length;
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
