import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FundPaymentDetailsService } from 'src/app/services/api/donatePage/fund-payment-details.service';

@Component({
  selector: 'app-bills-card',
  templateUrl: './bills-card.component.html',
  styleUrls: ['./bills-card.component.css'],
})
export class BillsCardComponent implements OnInit {
  @ViewChild('cardWidth', { static: true, read: ElementRef })
  cardWidth!: ElementRef;

  @Input()
  card!: any[];
  constructor(private paymentDetailsService: FundPaymentDetailsService) {}

  ngOnInit(): void {}

  uploadFile(cardName: string) {
    this.paymentDetailsService
      .getDetailsRequsiteFileLinks(cardName.toLowerCase())
      .subscribe(res => {
        if (res) window.open(res, 'blank');
      });
  }
}
