<div class="flex flex-col md:flex-row justify-between mb-10 items-start">
  <h3 class="text-h2-360 md:text-h2-heading text-primary-base xl:w-145">
    {{ title | uppercase }}
  </h3>
  <!-- Arrow buttons for screens more than 360 -->
  <div
    *ngIf="slide && !isMobile"
    class="arrow-block flex flex-row justify-between items-start w-34">
    <button
      type="button"
      class="text-accent-base cursor-pointer mt-[-6px] hover:text-secondary-base active:text-secondary-additional group"
      *ngIf="totalPages > 1"
      (click)="currentPage !== 1 && changePage(-1)"
      [disabled]="currentPage === 1">
      <svg
        class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional group-disabled:fill-gray-gray2 group-disabled:cursor-not-allowed"
        [icon]="'left-arrow'"
        iconCollection="button-icons-sprite"
        width="48px"
        height="48px"></svg>
    </button>
    <button
      type="button"
      class="text-accent-base cursor-pointer mt-[-6px] hover:text-secondary-base active:text-secondary-additional group"
      *ngIf="totalPages > 1"
      (click)="currentPage !== totalPages && changePage(+1)"
      [disabled]="currentPage === totalPages">
      <svg
        class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional group-disabled:fill-gray-gray2 group-disabled:cursor-not-allowed"
        [icon]="'right-arrow'"
        iconCollection="button-icons-sprite"
        width="48px"
        height="48px"></svg>
    </button>
  </div>
</div>
<div #container>
  <div class="overflow-hidden">
    <div
      class="flex flex-row relative transition-[left] duration-500 ease-in-out"
      [ngStyle]="{ width: overflowWidth, left: pagePosition }">
      <ng-container *ngFor="let i of SlidesArray; let index = index">
        <app-bills-card *ngIf="typeCard" [card]="i"></app-bills-card>
      </ng-container>
      <ng-content></ng-content>
    </div>
  </div>
</div>

<!-- Arrow buttons for screen 360 -->
<div
  *ngIf="slide && isMobile"
  class="arrow-block flex flex-row justify-between mx-auto items-start w-34 mt-7.75">
  <button
    type="button"
    class="text-accent-base cursor-pointer mt-[-6px] hover:text-secondary-base active:text-secondary-additional group"
    *ngIf="totalPages > 1"
    (click)="currentPage !== 1 && changePage(-1)"
    [disabled]="currentPage === 1">
    <svg
      class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional group-disabled:fill-gray-gray2 group-disabled:cursor-not-allowed"
      [icon]="'left-arrow'"
      iconCollection="button-icons-sprite"
      width="48px"
      height="48px"></svg>
  </button>
  <button
    type="button"
    class="text-accent-base cursor-pointer mt-[-6px] hover:text-secondary-base active:text-secondary-additional group"
    *ngIf="totalPages > 1"
    (click)="currentPage !== totalPages && changePage(+1)"
    [disabled]="currentPage === totalPages">
    <svg
      class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional group-disabled:fill-gray-gray2 group-disabled:cursor-not-allowed"
      [icon]="'right-arrow'"
      iconCollection="button-icons-sprite"
      width="48px"
      height="48px"></svg>
  </button>
</div>
