<form
  *ngIf="formOnTimeSubs"
  [formGroup]="formOnTimeSubs"
  (ngSubmit)="onSubmit()"
  class="flex flex-col text-gray-dark">
  <div class="flex flex-row justify-between items-center mb-4.25 xl:mb-1">
    <h3 class="w-48 md:w-147 text-smaller-bold md:text-main-text-bold">
      Оберіть суму вашого внеску
    </h3>
    <div class="select-wrap">
      <app-dropdown
        [ListArray]="currency"
        (OutChosedElem)="changeSelectedCurrency($event)"></app-dropdown>
    </div>
  </div>
  <app-summ-donate
    [sumArray]="choosedArray"
    [sumControl]="chosedSum"></app-summ-donate>

  <div
    [ngClass]="{
      'border-attention-base': formOnTimeSubs.invalid && formOnTimeSubs.touched
    }">
    <h3 class="text-smaller-bold md:text-main-text-bold">Введіть ваші дані</h3>
    <div
      class="flex justify-start items-start gap-y-2.5 md:gap-y-5 gap-x-5 flex-wrap mb-15 mt-5">
      <app-input
        [control]="name"
        [labelName]="'Імʼя'"
        [maxLength]="'60'"
        inputID="name"
        class="w-75 md:w-82 xl:w-75"></app-input>

      <app-input
        [control]="surname"
        [labelName]="'Прізвище'"
        [maxLength]="'60'"
        inputID="surname"
        class="w-75 md:w-82 xl:w-75"></app-input>

      <app-input
        [control]="email"
        [labelName]="'E-mail'"
        type="email"
        inputID="email"
        class="w-75 md:w-82 xl:w-75"></app-input>

      <div class="relative">
        <app-phone-input
          [control]="phone"
          [countryCode]="countryCode"></app-phone-input>
      </div>
    </div>
  </div>

  <div>
    <div
      class="flex flex-row justify-start mb-11.5 md:mb-15 text-details-360 md:text-details">
      <div class="mr-4">
        <label
          for="checkboxRead"
          class="flex w-8 h-8 rounded-lg border-2 border-gray-stroke cursor-pointer"
          [ngClass]="{
            'bg-primary-base': formOnTimeSubs.get('read')?.valid,
            'border-attention-base border':
              formOnTimeSubs.get('read')?.invalid &&
              formOnTimeSubs.get('read')?.touched
          }">
          <span
            class="text-gray-white"
            *ngIf="formOnTimeSubs.get('read')?.valid">
            <svg
              width="26px"
              height="26px"
              [icon]="'checkBox'"
              iconCollection="button-icons-sprite"
              class="fill-gray-white"></svg>
          </span>
        </label>
        <input
          class="hidden"
          id="checkboxRead"
          type="checkbox"
          formControlName="read" />
      </div>

      <div class="text-block">
        Я ознайомився/лась з
        <a
          (click)="onOpenOfertaGeneral()"
          class="cursor-pointer text-accent-base"
          >Публічною офертою щодо надання благодійної пожертви</a
        >
        та погоджуюся з тим, що введена мною особиста інформація буде
        використана згідно з
        <a
          (click)="onOpenFundPolicyGeneral()"
          class="cursor-pointer text-accent-base"
          >Політикою конфіденційності Фонду</a
        >.
      </div>
    </div>

    <div class="md:mb-15">
      <app-button
        *ngIf="!clicked; else safaryButton"
        class="w-72 md:w-82 xl:w-154"
        [buttonType]="'Primary'"
        [title]="'Перейти до сплати'"
        type="submit"></app-button>
    </div>
  </div>
</form>

<ng-template #safaryButton>
  <a [href]="safaryLink"
    ><app-button
      class="w-72 md:w-82 xl:w-154"
      [buttonType]="'Primary'"
      [title]="'Підтвердьте вашу дію'"
      type="button"></app-button>
  </a>
</ng-template>
