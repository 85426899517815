import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ourIdeaMockData } from 'src/mockData/aboutUs_OurIdea_MockData';

@Component({
  selector: 'app-our-idea',
  templateUrl: './our-idea.component.html',
  styleUrls: ['./our-idea.component.css'],
})
export class OurIdeaComponent implements OnInit {
  pageName: string = 'about-us/our-idea';
  links = ourIdeaMockData.pageMenuLinks;
  safeURL: SafeResourceUrl;

  constructor(private _sanitizer: DomSanitizer) {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
      ourIdeaMockData.videoURL,
    );
  }

  ngOnInit(): void {}
}
