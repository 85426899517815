import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-month-dementia-slide',
  templateUrl: './month-dementia-slide.component.html',
  styleUrl: './month-dementia-slide.component.css',
})
export class MonthDementiaSlideComponent {
  @Input()
  text!: string;
  @Input()
  title!: string;
  @Input()
  imgUrl?: string;
}
