import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { ITransferToReports } from 'src/app/models/transferToReports.interface';
import { TransferToReportsService } from 'src/app/services/api/donatePage/transfer-to-reports.service';
import { IAccordionData } from 'src/app/models/accordionModel.interface';
import { AccordionApiService } from 'src/app/services/api/accordionApi.service';
import { accordionList } from 'src/mockData/accordionHelpList';
import { requisitesPaid } from 'src/mockData/requisitesPaid';
import { FundPaymentDetailsService } from 'src/app/services/api/donatePage/fund-payment-details.service';

interface IAccordionList {
  title: string;
  HTML: SafeHtml;
}

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.css'],
})
export class DonateComponent implements OnInit, AfterViewInit, OnDestroy {
  public AlternativePaidArray: {
    logo: string;
    linkTo: string;
    type: string;
    description: string;
    name: string;
  }[] = [
    {
      type: '',
      logo: '',
      description: '',
      linkTo: '',
      name: '',
    },
  ];
  public requisitesPaid: any = requisitesPaid;
  public accordionList: IAccordionList[] = accordionList;
  public transferToReportsData$!: Observable<ITransferToReports>;
  otherMethodSubscr!: Subscription;
  reportLink: string = PAGE_ROUTES.reports;

  constructor(
    private cdRef: ChangeDetectorRef,
    private apiFaQService: AccordionApiService,
    private sanitizer: DomSanitizer,
    private transferToReportsService: TransferToReportsService,
    private getRequisitesService: FundPaymentDetailsService,
  ) {}

  ngOnInit(): void {
    this.fetchAccordionList().subscribe(data => {
      this.accordionList = data.map(faq => ({
        title: faq.title,
        HTML: this.sanitizer.bypassSecurityTrustHtml(faq.html),
      }));
    });
    this.otherMethodSubscr = this.transferToReportsService
      .getOthersMethodsDonateData()
      .subscribe(alternative => {
        if (alternative) {
          this.AlternativePaidArray = alternative;
        }
      });

    this.transferToReportsData$ =
      this.transferToReportsService.getTransferToReportsData();
    // wait backend
    // forkJoin(this.getRequisitesService.getData()).subscribe(res => {
    //   const requsitesArray: any[] = [];
    //   if (res) {
    //     res.forEach((data: any) => {
    //       const newDataArray = [];
    //       for (let key in data) {
    //         const newData = {
    //           name: key,
    //           value: data[key],
    //         };
    //         newDataArray.push(newData);
    //       }
    //       requsitesArray.push(newDataArray);
    //     });
    //     console.log(requsitesArray);
    //   }
    // });
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  fetchAccordionList(): Observable<IAccordionData[]> {
    return this.apiFaQService.getData('Help');
  }

  public ngOnDestroy() {
    if (this.otherMethodSubscr) {
      this.otherMethodSubscr.unsubscribe();
    }
  }
}
