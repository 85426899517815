import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ITransferToReports } from 'src/app/models/transferToReports.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TransferToReportsService {
  url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getTransferToReportsData(): Observable<ITransferToReports> {
    return this.http.get<ITransferToReports>(
      `${environment.apiUrl}/TransferToReports?id=1`,
    );
  }

  getOthersMethodsDonateData(): Observable<
    {
      logo: string;
      linkTo: string;
      type: string;
      description: string;
      name: string;
    }[]
  > {
    return this.http
      .get<
        {
          logo: string;
          link: string;
          type?: string;
          description: string;
          name: string;
        }[]
      >(this.url + '/FundPaymentDetails/anotherPaymentDetails')
      .pipe(
        map(data => {
          return data.map(item => ({
            logo: item.logo,
            linkTo: 'https://www.globalgiving.org/projects/help-people-with-dementia-in-ukraine/',
            type: 'alternative',
            description: item.description,
            name: item.name,
          }));
        }),
      );
  }
}
