<div
  class="flex overflow-hidden mx-auto md:w-full h-170 md:h-137.5 xl:h-full relative justify-center bg-primary-base">
  <div class="flex wide-container h-full relative">
    <img
      class="hidden xl:block absolute top-0 right-0 h-170 pc-position md:h-137.5 xl:h-full w-[1350px] object-cover"
      alt="{{ title }}"
      src="{{ imgUrl }}" />
    <img
      class="hidden md:block xl:hidden absolute mob-position top-0 right-0 h-170 md:h-137.5 xl:h-full object-contain"
      alt="{{ title }}"
      src="{{ imgUrl }}" />
    <img
      class="block md:hidden absolute -top-20 right-0 h-170 md:h-137.5 xl:h-full mob-position object-cover"
      alt="{{ title }}"
      src="{{ imgUrl }}" />
    <div
      class="mx-auto w-full h-full z-10 bg-[linear-gradient(0deg,#6E285F_27.17%,rgba(110,40,95,0.991615)_31.63%,rgba(110,40,95,0.967585)_35.27%,rgba(110,40,95,0.9296)_38.2%,rgba(110,40,95,0.879348)_40.55%,rgba(110,40,95,0.818519)_42.46%,rgba(110,40,95,0.7488)_44.05%,rgba(110,40,95,0.671881)_45.44%,rgba(110,40,95,0.589452)_46.78%,rgba(110,40,95,0.5032)_48.17%,rgba(110,40,95,0.414815)_49.76%,rgba(110,40,95,0.325985)_51.66%,rgba(110,40,95,0.2384)_54.02%,rgba(110,40,95,0.153748)_56.95%,rgba(110,40,95,0.0737185)_60.59%,rgba(110,40,95,0)_65.05%)] md:bg-[linear-gradient(90deg,#6E285F_40.16%,rgba(110,40,95,0.991615)_43.09%,rgba(110,40,95,0.967585)_45.48%,rgba(110,40,95,0.9296)_47.41%,rgba(110,40,95,0.879348)_48.95%,rgba(110,40,95,0.818519)_50.21%,rgba(110,40,95,0.7488)_51.25%,rgba(110,40,95,0.671881)_52.17%,rgba(110,40,95,0.589452)_53.04%,rgba(110,40,95,0.5032)_53.96%,rgba(110,40,95,0.414815)_55%,rgba(110,40,95,0.325985)_56.25%,rgba(110,40,95,0.2384)_57.8%,rgba(110,40,95,0.153748)_59.73%,rgba(110,40,95,0.0737185)_62.12%,rgba(110,40,95,0)_65.05%)]"></div>
  </div>
  <div class="content-container absolute z-20 pb-10 md:pb-20 bottom-0">
    <div class="flex items-end h-full pb-20 md:pb-39">
      <div
        class="flex flex-col items-center md:items-start justify-end text-gray-white px-4 md:px-0 w-full sm:w-120">
        <h3 class="text-h2-heading md:text-h1-heading mb-5">
          {{ title | uppercase }}
        </h3>
        <p class="text-smaller-main md:text-main-text">{{ text }}</p>
        <div class="md:w-55 w-full mt-10">
          <ng-content select=".btn"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
