<div *ngIf="isLoaded; else loader">
  <app-main-slider>
    <!--    <app-main-slides>-->
    <!--      <app-fourth-main-slide [title]="fourthBannerTitle">-->
    <!--      </app-fourth-main-slide>-->
    <!--    </app-main-slides>-->
    <app-main-slides>
      <app-month-dementia-slide
        [title]="'ВЕРЕСЕНЬ 2024 — МІСЯЦЬ ПРИВЕРНЕННЯ УВАГИ ДО ДЕМЕНЦІЇ'"
        [text]="'#ДЕМЕНЦІЯ_ЧАС_ДІЯТИ_ЗАРАЗ #МІСЯЦЬ_ДЕМЕНЦІЇ'"
        [imgUrl]="'assets/images/bannerPicture/month-of-dementia.webp'">
        <app-link-button
          class="btn"
          [title]="'Читати детальніше'"
          [linkButtonType]="'Primary'"
          routerLink="/about-us/news-and-media/media-centre/press-releases/6">
        </app-link-button>
      </app-month-dementia-slide>
    </app-main-slides>
    <app-main-slides>
      <app-second-main-slide
        [title]="
          'Дізнайтеся, як ви можете підтримати людей з деменцією та їх родини!'
        ">
        <app-link-button
          class="btn"
          [title]="'Долучитися'"
          [linkButtonType]="'Primary'"
          [routerLink]="getInvolvedLink">
        </app-link-button>
      </app-second-main-slide>
    </app-main-slides>
    <app-main-slides>
      <app-first-main-slide
        [title]="'ВІТАЄМО НА САЙТІ ФОНДУ «НЕЗАБУТНІ»'"
        [text]="
          'Тут ви знайдете інформацію про деменцію, практичні рекомендації, а також зможете отримати допомогу'
        "
        [imgUrl]="'assets/images/bannerPicture/banner-first-slide.jpg'">
        <app-link-button
          class="btn"
          [title]="'Отримати допомогу'"
          [linkButtonType]="'Primary'"
          routerLink="/get-support">
        </app-link-button>
      </app-first-main-slide>
    </app-main-slides>
    <app-main-slides>
      <app-third-main-slide
        [title]="
          'Підпишіться на наші соціальні мережі, щоб завжди бути у курсі головних подій фонду'
        ">
      </app-third-main-slide>
    </app-main-slides>
  </app-main-slider>

  <app-about-dementia-content
    [textData]="aboutDementiaTextBlock"
    [tabData]="aboutDementiaTabBlock"></app-about-dementia-content>

  <app-prevention-block
    [preventionTextBlock]="preventionTextBlock"
    [preventionCardsBlock]="preventionCardsBlock"></app-prevention-block>

  <app-about-block [aboutFondTextBlock]="aboutFondTextBlock"></app-about-block>

  <app-our-influence
    [ourAchievementsTextBlock]="ourAchievementsTextBlock"
    [ourAchievementsCardsBlock]="ourAchievementsCardsBlock"></app-our-influence>

  <app-our-projects-block
    [textContentData]="textContentData"></app-our-projects-block>

  <app-help-block [type]="'primary'" [pageName]="'main'"></app-help-block>

  <app-block-news></app-block-news>

  <app-subscribe-news></app-subscribe-news>
</div>

<ng-template #loader>
  <div class="pt-30">
    <div
      class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
      <div class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full gradient-animation"></div>
      </div>
    </div>
  </div>
</ng-template>
