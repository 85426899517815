<div class="w-full h-170 md:h-137.5 xl:h-162 overflow-hidden">
  <div
    class="bg-no-repeat bg-center relative sm:bg-right md:bg-center bg-cover h-full w-full bg-[url('assets/images/bannerPicture/fourth-banner-mob.png')] sm:bg-[url('assets/images/bannerPicture/fourth-banner-tablet.png')] xl:bg-[url('assets/images/bannerPicture/fourth-banner-pc3.png')]">
    <div class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
      <div
        class="flex flex-col h-full pb-20 md:pb-21 xl:pb-35 items-start justify-end text-gray-white w-120 md:w-160.25 px-4 md:pl-15 xl:pl-0 md:px-0">
        <h3
          class="text-h2-heading uppercase md:text-h1-heading md:mb-2.5 text-primary-additional"
          [innerHTML]="title"></h3>
        <div class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
          <a
            href="https://www.bit.ly/World-Alzheimer-Report-2024-Survey"
            target="_blank"
            class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
            Пройти опитування
          </a>
        </div>
      </div>
    </div>
    <img
      class="absolute right-2 bottom-0 w-20 h-20 sm:w-30 sm:h-30 md:w-40 md:h-40 xl:w-50 xl:h-50 object-contain"
      src="./assets/images/bannerPicture/adi-logo.png"
      alt="Alzheimer's Disiase International" />
  </div>
</div>
