import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
  selector: 'app-html-editor',
  standalone: false,
  templateUrl: './html-editor.component.html',
  styleUrl: './html-editor.component.css',
})
export class HtmlEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() text: string = '';
  @Input() placeholder: string = 'Введіть текст...';
  @Input() edit: boolean = false;
  @Input() buttonText: string = 'Результат';
  @Output() editedHtml: EventEmitter<any> = new EventEmitter<any>();
  title: string = 'editorTest';
  editor!: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3'] }],
    [],
    ['link', 'image'],
    ['horizontal_rule', 'format_clear'],
    ['code'],
  ];
  colorPresets = [
    '#E1326E',
    '#FA3778',
    '#FF4545',
    '#6E285F',
    '#873273',
    '#FFBE00',
    '#F2B500',
    '#454545',
    '#A0A0A0',
    '#F5F5F5',
    '#FFFFFF',
    '#E7E7E7',
    '#E1E1E1',
  ];
  constructor(private renderer: Renderer2) {}
  ngOnInit(): void {
    this.editor = new Editor({ history: true });
  }

  cssClassController(html: HTMLElement) {
    const links = html.querySelectorAll('a');
    const numberedLists = html.querySelectorAll('ol');
    const unNumberedLists = html.querySelectorAll('ul');
    const listsItem = html.querySelectorAll('li');
    const header1 = html.querySelectorAll('h1');
    const header2 = html.querySelectorAll('h2');
    const header3 = html.querySelectorAll('h3');
    const blockquote = html.querySelectorAll('blockquote');
    const breakBr = html.querySelectorAll('p');
    const imges = html.querySelectorAll('img');
    if (links.length) {
      this.addClassList(links, [
        'text-accent-base',
        'hover:text-accent-additional',
        'underline',
        'cursor-pointer',
      ]);
    }
    if (numberedLists.length) {
      this.addClassList(numberedLists, [
        'pb-7.5',
        'list-inside',
        'list-decimal',
        'ml-5',
      ]);
    }
    if (unNumberedLists.length) {
      this.addClassList(unNumberedLists, [
        'pb-7.5',
        'list-inside',
        'list-disc',
        'ml-5',
      ]);
    }
    if (header1.length) {
      this.addClassList(header1, [
        'text-primary-base',
        'md:text-h1-heading',
        'text-h1-360',
        'my-5',
        'uppercase',
      ]);
    }
    if (header2.length) {
      this.addClassList(header2, [
        'text-primary-base',
        'md:text-h2-heading',
        'text-h2-360',
        'my-5',
        'uppercase',
      ]);
    }
    if (header3.length) {
      this.addClassList(header3, [
        'text-primary-base',
        'md:text-h3-heading',
        'text-h3-360',
        'my-5',
        'uppercase',
      ]);
    }
    if (breakBr.length) {
      breakBr.forEach(p => {
        if (!p.innerText) {
          this.renderer.addClass(p, 'pb-7.5');
        }
      });
    }
    if (blockquote.length) {
      this.addClassList(blockquote, [
        'p-5',
        'border-l-2.5',
        'border-primary-base',
        'rounded-10',
        'flex',
        'w-full',
        'text-h3-subheading',
      ]);
    }
    if (listsItem.length) {
      const paragrapthInList: any = [];
      listsItem.forEach((elem: HTMLElement) => {
        paragrapthInList.push(elem.querySelector('p'));
      });
      this.addClassList(paragrapthInList, ['inline']);
    }
    if (imges.length) {
      this.addClassList(imges, [
        'rounded-30',
        'float-left',
        'mr-5',
        'mt-2.5',
        'mb-2.5',
      ]);
    }
    this.text = html.innerHTML;
  }

  addClassList(nodeList: any, classes: string[]) {
    nodeList.forEach((element: HTMLElement) => {
      classes.forEach(newClass => this.renderer.addClass(element, newClass));
    });
  }

  saveChanges(e: MouseEvent) {
    this.contentPrepared();
  }

  contentPrepared() {
    const html = this.renderer.createElement('div');
    html.innerHTML = this.text;
    this.cssClassController(html);
    this.editedHtml.emit(this.text);
  }

  ngOnDestroy() {
    this.editor.destroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.edit) {
      this.contentPrepared();
    }
  }
}
