import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FundPaymentDetailsService {
  url = environment.apiUrl + '/FundPaymentDetails/';
  linksUrl = environment.apiUrl + '/Requisites?currency=';
  constructor(private http: HttpClient) {}

  getData() {
    const DataArray = [];
    DataArray.push(this.http.get(this.url + 'uahdetails'));
    DataArray.push(this.http.get(this.url + 'eurdetails'));
    DataArray.push(this.http.get(this.url + 'eurdetails'));
    return DataArray;
  }

  getDetailsRequsiteFileLinks(key: string) {
    return this.http.get(this.linksUrl + key, { responseType: 'text' });
  }
}
