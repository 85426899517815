import { Component, HostListener, OnInit } from '@angular/core';
import {
  BannerPageService,
  IBannerPage,
} from 'src/app/services/api/banner-page.service';
import {
  GetHelpWithMedicationInterface,
  HelpWithMedicationService,
} from 'src/app/services/api/getSupportPages/help-with-medication.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  portalImageLinkModel,
  PortalLinksService,
} from '../../../services/api/portal-links.service';
import {
  TextCardModel,
  TextCardService,
} from '../../../services/api/text-card.service';

@Component({
  selector: 'app-help-with-medicine',
  templateUrl: './help-with-medicine.component.html',
  styleUrls: ['./help-with-medicine.component.css'],
})
export class HelpWithMedicineComponent implements OnInit {
  safeURLMental: SafeResourceUrl = '';
  textCarYoutube!: TextCardModel;
  pageName = 'help-with-medication';
  galleryTag: string = 'help-with-medication-medicinesImages';
  peopleGalleryTag: string = 'help-with-medication-peopleImages';
  peopleGallery: any[] = [];
  showCarousel: boolean = false;
  pageBannerData: IBannerPage = {
    title: '',
    text: '',
    imgUrl: '',
    pageName: '',
  };
  needHelpMedicationPageData: GetHelpWithMedicationInterface = {
    mainSectionText: '',
    linkSection: [],
    helpWithMedicine: [],
    helpWithMedicineCards: [],
  };
  navLinks: { id: number; title: string }[] = [];
  isHovered: boolean = false;
  videoLink: SafeResourceUrl | null = null;

  constructor(
    private pageService: HelpWithMedicationService,
    private pageBannerService: BannerPageService,
    private portalLinksService: PortalLinksService,
    private textCardS: TextCardService,
    private sanitizer: DomSanitizer,
  ) {}

  arrayListOfMedicine: portalImageLinkModel[] = [];

  ngOnInit(): void {
    this.pageBannerService.getBannersData(this.pageName).subscribe(el => {
      this.pageBannerData.title = el.title;
      this.pageBannerData.text = el.text;
      this.pageBannerData.imgUrl = el.imgUrl;
    });

    this.portalLinksService
      .getPortalLinksByTag(this.galleryTag)
      .subscribe(res => {
        this.arrayListOfMedicine = res;
        this.updateShowCarousel();
      });

    this.portalLinksService
      .getPortalLinksByTag(this.peopleGalleryTag)
      .subscribe(res => {
        this.peopleGallery = res.map(el => ({ ...el, src: el.imageUrl }));
      });

    this.pageService
      .getData()
      .subscribe((res: GetHelpWithMedicationInterface) => {
        this.needHelpMedicationPageData = res;
        this.needHelpMedicationPageData.helpWithMedicine.sort(
          (a, b) => a.id - b.id,
        );
        this.navLinks = this.needHelpMedicationPageData.helpWithMedicine.map(
          el => ({
            id: el.id,
            title: this.firsLetterUpper(el.title),
          }),
        );
      });
    this.textCardS
      .getTextsCardByTag('HelpWithMedicineYoutube')
      .subscribe(res => {
        if (res.length) {
          this.safeURLMental = this.sanitizeYouTubeUrl(res[0].link);
          this.textCarYoutube = res[0];
          this.videoLink = this.sanitizeYouTubeUrl(res[0].link);
        }
      });
  }

  firsLetterUpper(str: string) {
    const firstLetter = str.charAt(0).toUpperCase();
    const lowerStr = str.toLowerCase().slice(1);
    return firstLetter + lowerStr;
  }

  onChangeSlide(value: string) {
    if (value === 'nextSlide') {
      const deletedElement = this.arrayListOfMedicine.shift();
      this.arrayListOfMedicine.push(deletedElement!);
    }
    if (value === 'prevSlide') {
      const deletedElement = this.arrayListOfMedicine.pop();
      this.arrayListOfMedicine.unshift(deletedElement!);
    }
  }

  sanitizeYouTubeUrl(videoId: string): SafeResourceUrl {
    const url = `https://www.youtube.com/embed/${videoId}?si=8Z95Nckefci8xdMo`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateShowCarousel();
  }

  private updateShowCarousel(): void {
    const screenWidth = window.innerWidth;
    this.showCarousel =
      this.arrayListOfMedicine.length >= 3 || screenWidth <= 960;
  }
}
