export const accordionList = [
  {
    title:
      'Чи можна уникнути введення персональних даних при оформленні разового платежу на сайті?',
    HTML: `<div  class="text-main-text  ml-[-1rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease" style="height: 288px;"><p  class="pl-2.5 py-5 ng-star-inserted"> Наразі вам необхідно вводити свої персональні дані щоразу при оформленні платежу на сайті. Ми працюємо над створенням персонального кабінету, який дозволить уникнути повторного введення персональних даних. </p></div>`,
  },
  {
    title: 'З якою метою збираються мої персональні дані?',
    HTML: `<div  class="text-main-text  ml-[-1rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease" style="height: 288px;"><p  class="pl-2.5 py-5 ng-star-inserted"> Ми збираємо ваші персональні дані для звітування перед вами про використання ваших внесків. Ми піклуємося про ваші персональні дані, які захищені згідно з <a href="/" class="cursor-pointer"><span class="underline underline-offset-5 text-accent-base">політикою конфіденційності</span></a>   нашого сайту.</p></div>`,
  },
  {
    title: 'Як оформити щомісячний внесок?',
    HTML: `<div  class="text-main-text  ml-[-1rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease" style="height: 288px;"><p  class="pl-2.5 py-5 ng-star-inserted"> Ви можете легко та швидко створити регулярний платіж через ПриватБанк, Монобанк <p>asdasdasdasdasd</p> чи Patreon у розділі <a href="/get-involved/to-help?subscribe=monthly" class="cursor-pointer"><span class="underline underline-offset-5 text-accent-base">“Щомісячна допомога”</span></a> (посилання на форму «Щомісячна допомога»)</p></div>`,
  },
  {
    title: 'Як працює щомісячний внесок?',
    HTML: `<div  class="text-main-text  ml-[-1rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease" style="height: 288px;"><p  class="pl-2.5 py-5 ng-star-inserted"> Щомісяця в дату, яку ви обрали для списання коштів, у разі наявності коштів на картці, з вашої картки автоматично списуватиметься вказана вами сума. </p><p  class="pl-2.5 py-5 ng-star-inserted"> Система робить до 4 спроб списань, якщо всі вони неуспішні, наступна спроба — наступного місяця. </p><p  class="pl-2.5 py-5 ng-star-inserted"> Перше списання благодійного внеску відбудеться в обрану вами дату. Далі щомісяця будуть списуватися кошти. Виняток — якщо підписка була оформлена 29 лютого або 31-го числа будь-якого місяця. У такому разі наступне списання буде через 30 днів. </p></div>`,
  },
  {
    title:
      'Що, якщо в день зняття щомісячного внеску, на рахунку буде недостатньо коштів?',
    HTML: `<div  class="text-main-text mt-5 ml-[-1rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease" style="height: 288px;"><p  class="pl-2.5 py-5 ng-star-inserted"> Платіж не буде здійснено у разі недостатніх коштів на рахунку. Платіж відновиться наступного місяця, якщо на картці буде достатньо коштів. </p></div>`,
  },
  {
    title:
      'Чи можу я зробити внесок, перебуваючи за межами України, та в іноземній валюті?',
    HTML: `<div
    class="text-main-text ml-[-1rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease"
    style="height: 288px">
    <p class="pl-2.5 py-5 ng-star-inserted">
      Так, ви можете зробити внесок у валютах EUR, USD та GBP шляхом перерахування
      коштів за реквізитами
      <a href="/get-involved/to-help?subscribe=monthly" class="cursor-pointer"
        ><span class="underline underline-offset-5 text-accent-base"
          >реквізитами</span
        >
      </a>
      нашого фонду, швидким платежем через платіжну систему
      <a href="/get-involved/to-help?subscribe=monthly" class="cursor-pointer"
        ><span class="underline underline-offset-5 text-accent-base"
          >LiqPay</span
        ></a
        >, або через фандрейзингову платформу
      <a href="/get-involved/to-help?subscribe=monthly" class="cursor-pointer"
        ><span class="underline underline-offset-5 text-accent-base"
        >GlobalGiving</span
        ></a
      >
      та
      <a href="/join/donate?subscribe=monthly" class="cursor-pointer"
      ><span class="underline underline-offset-5 text-accent-base"
      >JustGiving</span
      ></a
      >.
      </p>
      </div>`,
  },
  {
    title: 'Де можна ознайомитись зі звітом про використання внесків фондом?',
    HTML: `<div  class="text-main-text mt-5 ml-[-1rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease" style="height: 288px;"><p  class="pl-2.5 py-5 ng-star-inserted"> Зі звітом про використання коштів можна ознайомитись на сторінці  <a href="/join/donate?subscribe=monthly" class="cursor-pointer"
      ><span class="underline underline-offset-5 text-accent-base"
      >“Звіти та документи”</span
      ></a
      >.</p></div>`,
  },
  {
    title:
      'Чи можна допомогти підопічним фонду шляхом цільової покупки або фінансуванням     окремого проєкту?',
    HTML: `<div  class="text-main-text mt-5 ml-[-1rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease" style="height: 288px;"><p  class="pl-2.5 py-5 ng-star-inserted"> Так, просимо написати на електронну пошту фонду <span class="underline underline-offset-5 text-accent-base"
    >info@nezabutni.org</span
    > з темою листа «Адресна допомога» і з вами зв’яжеться співробітник фонду з приводу цього питання.</p></div>`,
  },
];

export const accordionListMedicalAssistance = [
  {
    title: 'Я турбуюся, що у мене деменція',
    HTML: `<ul class="ml-[-1rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease">
  <li class="underline underline-offset-5 text-accent-base cursor-pointer pl-3 mb-5 ng-star-inserted">
    <a>Інші українські благодійні фонди </a>
  </li>
  <li class="underline underline-offset-5 text-accent-base cursor-pointer pl-3 mb-5 ng-star-inserted">
    <a>Міжнародні благодійні фонди</a>
  </li>
  <li class="underline underline-offset-5 text-accent-base cursor-pointer pl-3 mb-5 ng-star-inserted">
    <a>Застосунки, які розвивають пам’ять</a>
  </li>
  <li class="underline underline-offset-5 text-accent-base cursor-pointer pl-3 mb-5  ng-star-inserted">
    <a>Тести</a>
  </li>
  <li class="underline underline-offset-5 text-accent-base cursor-pointer pl-3 mb-5  ng-star-inserted">
    <a>Книжки</a>
  </li>
  <li class="underline underline-offset-5 text-accent-base cursor-pointer pl-3  ng-star-inserted">
    <a>Фільми</a>
  </li>
</ul>`,
  },
  {
    title: 'У мене деменція',
    HTML: `<div  class="text-main-text mt-5 ml-[-1rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease" style="height: 288px;"><p  class="pl-2.5 py-5 ng-star-inserted"> Так, просимо написати на електронну пошту фонду <span class="underline underline-offset-5 text-accent-base"
    >info@nezabutni.org</span
    > з темою листа «Адресна допомога» і з вами зв’яжеться співробітник фонду з приводу цього питання.</p></div>`,
  },
  {
    title: 'Я доглядальник, родич або друг',
    HTML: `<div  class="text-main-text mt-5 ml-[-1rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease" style="height: 288px;"><p  class="pl-2.5 py-5 ng-star-inserted"> Так, просимо написати на електронну пошту фонду <span class="underline underline-offset-5 text-accent-base"
    >info@nezabutni.org</span
    > з темою листа «Адресна допомога» і з вами зв’яжеться співробітник фонду з приводу цього питання.</p></div>`,
  },
];
