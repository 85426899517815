<main>
  <div class="pb-1">
    <div
      class="content-container flex flex-col items-center md:items-start pt-15.5 mb-30 xl:mb-60">
      <ng-container *ngIf="!isShowSuccessMessage; else elseBlock">
        <h1
          class="w-82 md:w-full text-h1-360 md:text-h1-narrow text-primary-base mb-5">
          ШКОДА, ВИ ВИРІШИЛИ ВІДПИСАТИСЯ :&#40;
        </h1>
        <p
          class="w-82 md:w-full text-smaller-main md:text-main-text text-gray-dark mb-14.75">
          Напишіть адресу вашої електронної пошти нижче, щоб більше не
          отримувати листи з новинами від фонду “Незабутні”.
        </p>

        <form
          class="w-82 md:w-120"
          [formGroup]="unsubscribeForm"
          (ngSubmit)="onSubmit()">
          <app-input
            [control]="unsubscribeForm.controls.userEmail"
            [labelName]="'E-mail'"
            type="email"
            inputID="email"></app-input>

          <app-button
            class="w-82 md:w-120 mt-5"
            [buttonType]="'Secondary'"
            [title]="'Відписатись'"
            type="submit"></app-button>
        </form>
      </ng-container>

      <ng-template #elseBlock>
        <div class="h-75">
          <h1
            class="w-82 md:w-full text-h1-360 md:text-h1-narrow text-primary-base uppercase mb-5">
            Ви успішно відписалися від новин!
          </h1>
        </div>
      </ng-template>
    </div>

    <app-help-block [type]="'primary'"></app-help-block>
  </div>
</main>
