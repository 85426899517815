import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './layouts/footer/footer.component';
import { DonateComponent } from './pages/donate/donate.component';
import { ResultPaymentComponent } from './pages/donate/result-payment/result-payment.component';
import { SalivonOleksandrComponent } from './pages/salivon-oleksandr/salivon-oleksandr.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { NotFoundComponent } from './pages/404-not-found/404-not-found.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  DropDownListModule,
  ComboBoxModule,
  AutoCompleteModule,
  MultiSelectModule,
  ListBoxModule,
  DropDownTreeModule,
  MentionModule,
} from '@syncfusion/ej2-angular-dropdowns';
import { MediaAboutUsComponent } from './pages/about-us/news-and-media/media-about-us/media-about-us.component';
import { NewsComponent } from './pages/about-us/news-and-media/news/news.component';
import { CardComponent } from './pages/about-us/news-and-media/news/card/card.component';
import { NewsFilterComponent } from './pages/about-us/news-and-media/news/news-filter/news-filter.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { NewsAndMediaComponent } from './pages/about-us/news-and-media/news-and-media.component';
import { StatisticComponent } from './pages/about-us/news-and-media/statistic/statistic.component';
import { MediaCenterComponent } from './pages/about-us/news-and-media/media-center/media-center.component';
import { AboutFundComponent } from './pages/about-us/about-fund/about-fund.component';
import { OurPeopleComponent } from './pages/about-us/our-people/our-people.component';
import { OurProgramsComponent } from './pages/about-us/our-programs/our-programs.component';
import { OurIdeaComponent } from './pages/about-us/about-fund/our-idea/our-idea.component';
import { HistoryComponent } from './pages/about-us/about-fund/history/history.component';
import { ReportsComponent } from './pages/about-us/about-fund/reports/reports.component';
import { ContactsComponent } from './pages/about-us/about-fund/contacts/contacts.component';
import { SupervisoryBoardComponent } from './pages/about-us/our-people/supervisory-board/supervisory-board.component';
import { FundTeamComponent } from './pages/about-us/our-people/fund-team/fund-team.component';
import { AdvisoryGroupComponent } from './pages/about-us/our-people/advisory-group/advisory-group.component';
import { OurPartnersComponent } from './pages/about-us/our-people/our-partners/our-partners.component';
import { VacanciesComponent } from './pages/about-us/our-people/vacancies/vacancies.component';
import { HelpDuringTheWarComponent } from './pages/about-us/our-programs/help-during-the-war/help-during-the-war.component';
import { SupportForFamiliesComponent } from './pages/about-us/our-programs/support-for-families/support-for-families.component';
import { FriendlySocietyComponent } from './pages/about-us/our-programs/friendly-society/friendly-society.component';
import { StudyComponent } from './pages/about-us/our-programs/study/study.component';
import { ResearchComponent } from './pages/about-us/our-programs/research/research.component';
import { FAQComponent } from './pages/about-dementia/about-dementia-second-level/faq/faq.component';
import { AboutDementiaComponent } from './pages/about-dementia/about-dementia.component';
import { NeedHelpComponent } from './pages/need-help/need-help.component';
import { HelpWithMedicineComponent } from './pages/need-help/help-with-medicine/help-with-medicine.component';
import { PsychologicalSupportComponent } from './pages/need-help/psychological-support/psychological-support.component';
import { LegalAssistanceComponent } from './pages/need-help/legal-assistance/legal-assistance.component';
import { DoctorConsultationComponent } from './pages/need-help/doctor-consultation/doctor-consultation.component';
import { CareConsultationComponent } from './pages/need-help/care-consultation/care-consultation.component';
import { RehabilitationProgramComponent } from './pages/need-help/rehabilitation-program/rehabilitation-program.component';
import { InformationalAssistanceComponent } from './pages/need-help/informational-assistance/informational-assistance.component';
import { CrisisAssistanceComponent } from './pages/need-help/crisis-assistance/crisis-assistance.component';
import { HelpWithGeneratorsComponent } from './pages/need-help/crisis-assistance/help-with-generators/help-with-generators.component';
import { HelpWithEvacuationComponent } from './pages/need-help/crisis-assistance/help-with-evacuation/help-with-evacuation.component';
import { HelpAbroadComponent } from './pages/need-help/crisis-assistance/help-abroad/help-abroad.component';
import { IWorryAboutMemoryComponent } from './pages/need-help/what-to-do-if/i-worry-about-memory/i-worry-about-memory.component';
import { JustDiagnosedComponent } from './pages/need-help/what-to-do-if/just-diagnosed/just-diagnosed.component';
import { RelativeHasDementiaComponent } from './pages/need-help/what-to-do-if/relative-has-dementia/relative-has-dementia.component';
import { ProfessionalCaregiverComponent } from './pages/need-help/what-to-do-if/professional-caregiver/professional-caregiver.component';
import { MedicalEmployeeComponent } from './pages/need-help/what-to-do-if/medical-employee/medical-employee.component';
import { JoinComponent } from './pages/join/join.component';
import { FindInformationComponent } from './pages/find-information/find-information.component';
import { ActivitiesComponent } from './pages/activities/activities.component';

import { BlockNewsComponent } from './pages/about-us/news-and-media/news/block-news/block-news.component';

import { TaxReportsComponent } from './pages/about-us/about-fund/reports/tax-reports/tax-reports.component';

import { ReportsCardComponent } from './pages/about-us/about-fund/reports/reports-card/reports-card.component';
import { AnnualReportsComponent } from './pages/about-us/about-fund/reports/annual-reports/annual-reports.component';
import { DocumentsReportsComponent } from './pages/about-us/about-fund/reports/documents-reports/documents-reports.component';

import { ContactItemComponent } from './components/contact-item/contact-item.component';
import { SocialLinkComponent } from './components/social-link/social-link.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';

import { BecomeVolunteerComponent } from './pages/join/become-volunteer/become-volunteer.component';
import { FundMemberComponent } from './pages/about-us/our-people/fund-team/fund-member/fund-member.component';
import { MonthlyReportsComponent } from './pages/about-us/about-fund/reports/monthly-reports/monthly-reports.component';
import { OurInfluenceComponent } from './components/our-influence/our-influence.component';
import { ImpactCardComponent } from './components/impact-card/impact-card.component';
import { ButtonComponent } from './components/button/button.component';
import { SvgFlowerComponent } from './components/svg-icons/icon-flower/icon-flower.component';
import { ContentsComponent } from './components/table-of-content/table-of-content.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { MainComponent } from './components/main/main.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { BillsCardComponent } from './components/bills-card/bills-card.component';
import { CardAlternativeComponent } from './components/bills-card-alternative/card-alternative.component';
import { CardHelpComponent } from './components/card-help/card-help.component';
import { SliderComponent } from './components/slider/slider.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { SingleHelpComponent } from './components/forms/single-help/single-help.component';
import { SubscribeHelpComponent } from './components/forms/subscribe-help/subscribe-help.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { SubmenuAboutDementiaComponent } from './components/main-menu/submenu/submenu-about-dementia/submenu-about-dementia.component';
import { SubmenuItemsListComponent } from './components/main-menu/submenu/submenu-items-list/submenu-items-list.component';
import { SubmenuAboutUsComponent } from './components/main-menu/submenu/submenu-about-us/submenu-about-us.component';
import { SubmenuNeedHelpComponent } from './components/main-menu/submenu/submenu-need-help/submenu-need-help.component';
import { FilterComponent } from './components/filter/filter.component';
import { InformationComponent } from './components/information/information.component';
import { ProductsComponent } from './components/products/products.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { LeftComponent } from './components/pagination/icon/left/left.component';
import { RightComponent } from './components/pagination/icon/right/right.component';
import { BlockTextComponent } from './components/block-text/block-text.component';
import { InfoCardsComponent } from './components/info-cards/info-cards.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { MainSliderComponent } from './components/main-slider/main-slider.component';
import { ThirdMainSlideComponent } from './components/main-slider/third-main-slide/third-main-slide.component';
import { SecondMainSlideComponent } from './components/main-slider/second-main-slide/second-main-slide.component';
import { MainSlidesComponent } from './components/main-slider/main-slides/main-slides.component';
import { AboutDementiaContentComponent } from './components/about-dementia-content/about-dementia-content.component';
import { FirstMainSlideComponent } from './components/main-slider/first-main-slide/first-main-slide.component';
import { IconfilterComponent } from './components/filter/icon-filter/iconfilter/iconfilter.component';
import { DowncheckComponent } from './components/filter/icon-filter/downcheck/downcheck.component';
import { HelpBlockComponent } from './components/help-block/help-block.component';
import { HelpCardComponent } from './components/help-block/help-card/help-card.component';
import { SubscribeNewsComponent } from './components/subscribe-news/subscribe-news.component';
import { AboutBlockComponent } from './components/about-block/about-block.component';
import { OurProjectsBlockComponent } from './components/our-projects-block/our-projects-block.component';
import { FlowerSvgComponent } from './components/our-projects-block/flower/flower.component';
import { PageBannerItemComponent } from './components/page-banner-item/page-banner-item.component';
import { PreventionBlockComponent } from './components/prevention-block/prevention-block.component';
import { PreventionCardComponent } from './components/prevention-block/prevention-card/prevention-card.component';
import { MoreInfoComponent } from './components/more-info/more-info.component';
import { MoreInfoCardComponent } from './components/more-info-card/more-info-card.component';
import { ShareYourStoryComponent } from './components/share-your-story/share-your-story.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SupportBannerComponent } from './components/support-banner/support-banner.component';
import { SendRequestComponent } from './components/send-request/send-request.component';
import { OkeyComponent } from './components/send-request/icon/okey/okey.component';
import { FundMemberCardItemComponent } from './components/fund-member-card-item/fund-member-card-item.component';
import { VolunteerCardItemComponent } from './components/volunteer-card-item/volunteer-card-item.component';
import { ICONS_PATH } from './components/svg-icons/svg-icon/svg-icon-path';
import { SummDonateComponent } from './components/forms/single-help/summ-donate/summ-donate.component';
import { LinkButtonComponent } from './components/link-button/link-button.component';
import { VisionaryCardComponent } from './components/visionary-card/visionary-card.component';
import { FundPartnersBlockComponent } from './components/fund-partners-block/fund-partners-block.component';
import { InputComponent } from './components/forms/input/input.component';
import { PhoneInputComponent } from './components/forms/phone-input/phone-input.component';
import { CountryCodeSelectComponent } from './components/forms/phone-input/country-code-select/country-code-select.component';
import { CountryOptionComponent } from './components/forms/phone-input/country-code-select/country-option/country-option.component';
import { TabsHorizontalComponent } from './components/tabs-horizontal/tabs-horizontal.component';
import { TabHorizontalViewComponent } from './components/tabs-horizontal/tab-horizontal-view/tab-horizontal-view.component';
import { DetailedNewsComponent } from './pages/about-us/news-and-media/news/detailed-news/detailed-news.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CarouselSlideComponent } from './components/carousel/carousel-slide/carousel-slide.component';
import { MonthlyReportsCardComponent } from './pages/about-us/about-fund/reports/monthly-reports/monthly-reports-card/monthly-reports-card.component';
import { CrissisAssistanceFormHelpComponent } from './pages/need-help/crisis-assistance/crissis-assistance-form-help/crissis-assistance-form-help.component';
import { ResearchCardComponent } from './pages/about-us/our-programs/research/research-card/research-card/research-card.component';
import { OtherPublicationsCardComponent } from './pages/about-us/our-programs/research/other-publications-card/other-publications-card/other-publications-card.component';
import { CurrentResearchInfoComponent } from './pages/about-us/our-programs/research/current-research-info/current-research-info/current-research-info.component';
import { MobileMainMenuComponent } from './components/main-menu/mobile-main-menu/mobile-main-menu.component';
import { BecomePartnerComponent } from './pages/join/become-partner/become-partner.component';
import { SubmenuJoinComponent } from './components/main-menu/submenu/submenu-join/submenu-join.component';
import { AgreementConfig } from 'src/mockData/agreement-docs-config';
import { TextareaComponent } from './components/forms/textarea/textarea.component';
import { TestBackendComponent } from './pages/test-backend/test-backend.component';
import { ScrollToSectionDirective } from './directives/scroll-to-section.directive';
import { PressReleasesComponent } from './pages/about-us/news-and-media/media-center/press-releases/press-releases.component';
import { PressReleasesDetailsComponent } from './pages/about-us/news-and-media/media-center/press-releases/press-releases-details/press-releases-details.component';
import { PressReleaseCardComponent } from './pages/about-us/news-and-media/media-center/press-releases/press-release-card/press-release-card.component';
import { GlossaryComponent } from './pages/about-us/news-and-media/media-center/glossary/glossary.component';
import { HowToTalkAboutDementiaInTheMediaComponent } from './pages/about-us/news-and-media/media-center/how-to-talk-about-dementia-in-the-media/how-to-talk-about-dementia-in-the-media.component';
import { KeyFactsAboutDementiaComponent } from './pages/about-us/news-and-media/media-center/key-facts-about-dementia/key-facts-about-dementia.component';
import { DynamicHtmlComponentComponent } from './components/dynamic-html-component/dynamic-html-component.component';
import { AboutDementiaThirdLevelComponent } from 'src/app/pages/about-dementia/about-dementia-third-level/about-dementia-third-level.component';
import { PageLinkCardComponent } from './components/page-link-cards-block/page-link-card/page-link-card.component';
import { WhatDementiaIsComponent } from './pages/about-dementia/about-dementia-second-level/what-dementia-is/what-dementia-is.component';
import { DementiaTypesComponent } from './pages/about-dementia/about-dementia-second-level/dementia-types/dementia-types.component';
import { DiagnosisTherapyComponent } from './pages/about-dementia/about-dementia-second-level/diagnosis-therapy/diagnosis-therapy.component';
import { ProphylaxisComponent } from './pages/about-dementia/about-dementia-second-level/prophylaxis/prophylaxis.component';
import { ScrollToSectionAfterLoadDataDirective } from 'src/app/directives/scroll-to-section-after-load-data.directive';
import { PageLinkCardsBlockComponent } from './components/page-link-cards-block/page-link-cards-block.component';
import { WideCardComponent } from './components/wide-card/wide-card.component';

import { YouTubePlayerModule } from '@angular/youtube-player';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { LetterForDonationComponent } from './components/letters/letter-for-donation/letter-for-donation.component';
import { EmailForSubscriptionComponent } from './components/letters/email-for-subscription/email-for-subscription.component';
import { SvgIconModuleModule } from 'src/app/components/svg-icons/svg-icon/svg-icon-module.module';
import { OrganizeFundraiserComponent } from './pages/join/organize-fundraiser/organize-fundraiser.component';
import { CopyButtonComponent } from './components/copy-button/copy-button.component';
import { OtherMethodToJoinComponent } from './components/other-method-to-join/other-method-to-join.component';
import { UnderConstructionPageComponent } from './pages/under-construction-page/under-construction-page.component';
import { DatePipe } from '@angular/common';
import { UnsubscribePageComponent } from './pages/unsubscribe-page/unsubscribe-page.component';
import { ResearchDetailPageComponent } from './pages/about-us/our-programs/research/research-detail-page/research-detail-page.component';
import { SliceTextDotsPipe } from './pipes/slice-text-dots.pipe';
import { PaginationMediaAboutUsComponent } from './components/pagination/pagination-media-about-us/pagination-media-about-us.component';
import { EventsCardComponent } from './pages/activities/events-card/events-card.component';
import { DetailedEventsComponent } from './pages/activities/detailed-events/detailed-events.component';
import { PhoneWithLinkComponent } from './components/phone-with-link/phone-with-link.component';
import { NgXEditorModule } from './admin/components/ngx-editor/ngx-editor.module';
import { TokenInterceptor } from './admin/auth/services/token.interceptor';
import { PhoneLinkDirective } from './directives/phone-link.directive';
import { FourthMainSlideComponent } from './components/main-slider/fourth-main-slide/fourth-main-slide.component';
import { AdaptiveSliderModule } from './components/adaptive-slider/adaptive-slider.module';
import { UniversalNavPageComponent } from './components/universal-nav-page/universal-nav-page.component';
import { AppContactFormAdmComponent } from './admin/components/app-contact-form-adm/app-contact-form-adm.component';
import { MonthDementiaSlideComponent } from './components/main-slider/month-dementia-slide/month-dementia-slide.component';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    OurInfluenceComponent,
    ImpactCardComponent,
    ButtonComponent,
    HeaderComponent,
    SvgFlowerComponent,
    ContentsComponent,
    CookiesComponent,
    FooterComponent,
    MainComponent,
    DonateComponent,
    ResultPaymentComponent,
    AccordionComponent,
    BillsCardComponent,
    CardAlternativeComponent,
    CardHelpComponent,
    SliderComponent,
    TabsComponent,
    TabComponent,
    SingleHelpComponent,
    SubscribeHelpComponent,
    SalivonOleksandrComponent,
    MainPageComponent,
    MainMenuComponent,
    SubmenuAboutDementiaComponent,
    SubmenuItemsListComponent,
    SubmenuAboutUsComponent,
    SubmenuNeedHelpComponent,
    FilterComponent,
    InformationComponent,
    ProductsComponent,
    PaginationComponent,
    NotFoundComponent,
    MediaAboutUsComponent,
    BreadcrumbComponent,
    LeftComponent,
    RightComponent,
    NewsComponent,
    CardComponent,
    NewsFilterComponent,
    BlockTextComponent,
    InfoCardsComponent,
    InfoCardComponent,
    MainSliderComponent,
    ThirdMainSlideComponent,
    SecondMainSlideComponent,
    MainSlidesComponent,
    FirstMainSlideComponent,
    AboutDementiaContentComponent,
    AboutDementiaComponent,
    AboutUsComponent,
    NewsAndMediaComponent,
    StatisticComponent,
    MediaCenterComponent,
    AboutFundComponent,
    OurPeopleComponent,
    OurProgramsComponent,
    OurIdeaComponent,
    HistoryComponent,
    ReportsComponent,
    ContactsComponent,
    SupervisoryBoardComponent,
    FundTeamComponent,
    AdvisoryGroupComponent,
    OurPartnersComponent,
    VacanciesComponent,
    HelpDuringTheWarComponent,
    SupportForFamiliesComponent,
    FriendlySocietyComponent,
    StudyComponent,
    ResearchComponent,
    IconfilterComponent,
    DowncheckComponent,
    FAQComponent,
    NeedHelpComponent,
    HelpWithMedicineComponent,
    PsychologicalSupportComponent,
    LegalAssistanceComponent,
    DoctorConsultationComponent,
    CareConsultationComponent,
    RehabilitationProgramComponent,
    InformationalAssistanceComponent,
    CrisisAssistanceComponent,
    HelpWithGeneratorsComponent,
    HelpWithEvacuationComponent,
    HelpAbroadComponent,
    IWorryAboutMemoryComponent,
    JustDiagnosedComponent,
    RelativeHasDementiaComponent,
    ProfessionalCaregiverComponent,
    MedicalEmployeeComponent,
    JoinComponent,
    FindInformationComponent,
    ActivitiesComponent,
    HelpBlockComponent,
    HelpCardComponent,
    SubscribeNewsComponent,
    AboutBlockComponent,
    OurProjectsBlockComponent,
    FlowerSvgComponent,
    PageBannerItemComponent,
    PreventionBlockComponent,
    PreventionCardComponent,
    MoreInfoComponent,
    MoreInfoCardComponent,
    BlockNewsComponent,
    ShareYourStoryComponent,
    DropdownComponent,
    TaxReportsComponent,
    SupportBannerComponent,
    ReportsCardComponent,
    AnnualReportsComponent,
    DocumentsReportsComponent,
    SendRequestComponent,
    OkeyComponent,
    ContactItemComponent,
    SocialLinkComponent,
    ContactFormComponent,
    AboutDementiaThirdLevelComponent,
    CopyClipboardDirective,
    FundMemberCardItemComponent,
    VolunteerCardItemComponent,
    BecomeVolunteerComponent,
    FundMemberComponent,
    MonthlyReportsComponent,
    LinkButtonComponent,
    VisionaryCardComponent,
    FundPartnersBlockComponent,
    InputComponent,
    SummDonateComponent,
    PhoneInputComponent,
    CountryCodeSelectComponent,
    CountryOptionComponent,
    DetailedNewsComponent,
    CarouselComponent,
    CarouselSlideComponent,
    MonthlyReportsCardComponent,
    CrissisAssistanceFormHelpComponent,
    TabsHorizontalComponent,
    TabHorizontalViewComponent,
    ResearchCardComponent,
    OtherPublicationsCardComponent,
    CurrentResearchInfoComponent,
    BecomePartnerComponent,
    SubmenuJoinComponent,
    MobileMainMenuComponent,
    TextareaComponent,
    TestBackendComponent,
    PressReleasesComponent,
    PressReleasesDetailsComponent,
    PressReleaseCardComponent,
    GlossaryComponent,
    HowToTalkAboutDementiaInTheMediaComponent,
    KeyFactsAboutDementiaComponent,
    DynamicHtmlComponentComponent,
    PageLinkCardComponent,
    WhatDementiaIsComponent,
    DementiaTypesComponent,
    DiagnosisTherapyComponent,
    ProphylaxisComponent,
    ScrollToSectionAfterLoadDataDirective,
    PageLinkCardsBlockComponent,
    WideCardComponent,
    ImageSliderComponent,
    LetterForDonationComponent,
    EmailForSubscriptionComponent,
    OrganizeFundraiserComponent,
    CopyButtonComponent,
    OtherMethodToJoinComponent,
    UnderConstructionPageComponent,
    UnsubscribePageComponent,
    ResearchDetailPageComponent,
    SliceTextDotsPipe,
    PaginationMediaAboutUsComponent,
    EventsCardComponent,
    DetailedEventsComponent,
    PhoneWithLinkComponent,
    PhoneLinkDirective,
    FourthMainSlideComponent,
    UniversalNavPageComponent,
    MonthDementiaSlideComponent,
    // ScrollToSectionDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    DropDownListModule,
    NgxPaginationModule,
    HttpClientModule,
    ClipboardModule,
    OverlayModule,
    BrowserAnimationsModule,
    YouTubePlayerModule,
    NgxMaskModule.forRoot({
      validation: false,
    }),
    SvgIconModuleModule,
    ComboBoxModule,
    AutoCompleteModule,
    MultiSelectModule,
    ListBoxModule,
    DropDownTreeModule,
    MentionModule,
    NgXEditorModule,
    AdaptiveSliderModule,
    ScrollToSectionDirective,
    AppContactFormAdmComponent,
  ],
  providers: [
    DatePipe,
    HttpClientModule,
    AgreementConfig,
    {
      provide: ICONS_PATH,
      useValue: 'assets/icons/svg-sprites',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [LinkButtonComponent],
})
export class AppModule {}
