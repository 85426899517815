<div
  class="w-full overflow-hidden h-170 md:h-137.5 xl:h-162 flex-center justify-end">
  <div
    class="w-full h-full bg-no-repeat bgMobilePicture md:bg-left bg-cover"
    [ngStyle]="{ 'background-image': backgroundImage }">
    <div
      class="flex w-full h-full bg-mobilegradienFirstBanner md:bg-gradientFirstBanner">
      <div class="content-container flex items-end h-full pb-20 md:pb-39">
        <div
          class="flex flex-col items-center md:items-start justify-end text-gray-white px-4 md:px-0 w-full sm:w-120">
          <h3 class="text-h2-heading md:text-h1-heading mb-5">
            {{ title | uppercase }}
          </h3>
          <p class="text-smaller-main md:text-main-text">{{ text }}</p>
          <div class="md:w-55 w-full mt-10">
            <ng-content select=".btn"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
