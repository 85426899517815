<ng-container [ngSwitch]="true">
  <ng-container *ngSwitchDefault>
    <ul class="accordion {{ classList }}">
      <li
        *ngFor="let item of AccordionsList; let i = index"
        class="first:border-t-2 first:border-gray-stroke"
        [class.active]="item.active">
        <div
          class="text-gray-dark text-h3-360 md:text-h3-subheading border-b-2 border-gray-stroke pt-6 pb-0.5 pl-18">
          <div class="relative cursor-pointer" (click)="toggle(i)">
            <svg
              width="22px"
              height="13px"
              icon="arrow-bottom"
              iconCollection="button-icons-sprite"
              [ngClass]="{ 'rotate-180': item.active }"
              class="absolute top-2 left-[-40px] transition-all duration-700 ease fill-accent-base"></svg>
            <h3>{{ item.title }}</h3>
          </div>
          <div
            class="text-main-text mt-5 ml-[-2.5rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease"
            #innerText
            [style.height.px]="item.active ? innerText.scrollHeight : 0">
            <ng-container *ngIf="item.text">
              <p class="pl-2.5 py-5" *ngFor="let text of item.text">
                {{ text }}
              </p>
            </ng-container>
            <ng-container *ngIf="item.string">
              <p class="pl-2.5 mb-5">{{ item.string }}</p>
            </ng-container>
            <ng-container *ngIf="item.HTML">
              <div class="pl-2.5 mb-5" [innerHTML]="item.HTML"></div>
            </ng-container>
            <ng-container *ngIf="item.html">
              <div class="pl-2.5 mb-5" [innerHTML]="item.html"></div>
            </ng-container>
            <ng-container *ngIf="item.links">
              <a
                *ngFor="let link of item.links; index as i"
                class="inline-flex ml-4.5 mb-5 text-accent-base border-b-[1px] border-accent-base hover:text-secondary-additional hover:border-secondary-additional duration-300"
                href="{{ link.url }}"
                target="_blank"
                (click)="stopEventBubbling($event)">
                {{ i + 1 }}{{ '. ' }}{{ link.title }}
              </a>
            </ng-container>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngSwitchCase="mobile">
    <ul
      class="{{
        classList
      }} bg-gray-white rounded-30 border-2 border-gray-card overflow-hidden">
      <li
        *ngFor="let item of AccordionsList; let i = index"
        class="last:border-b-0 border-b-2 border-gray-card"
        [class.active]="item.active"
        [ngClass]="{ 'bg-gray-card': item.active }">
        <div
          class="cursor-pointer text-gray-dark text-h3-subheading pt-6 pb-0.5 p-5"
          (click)="toggle(i)">
          <div class="relative text-primary-additional flex flex-row">
            <h3 [ngClass]="{ 'text-accent-base': item.active }">
              {{ item.title }}
            </h3>
            <div class="w-8 h-8">
              <svg
                width="22px"
                height="13px"
                icon="arrow-bottom"
                iconCollection="button-icons-sprite"
                [ngClass]="{ 'rotate-180': item.active }"
                class="absolute top-2 right-0 transition-all duration-700 ease fill-accent-base"></svg>
            </div>
          </div>
          <div
            class="text-smaller-main mt-5 overflow-hidden transition-[height] duration-1000 ease"
            #innerText
            [style.height.px]="item.active ? innerText.scrollHeight : 0">
            <ng-container *ngIf="item.text">
              <div class="mb-5" [innerHTML]="item.text"></div>
            </ng-container>
            <div *ngIf="item.linkTo" class="w-full mb-10">
              <app-link-button
                [linkButtonType]="'Secondary'"
                [title]="'Детальніше'"
                [url]="item.linkTo"></app-link-button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngSwitchCase="mobileMenu">
    <ul class="{{ classList }} overflow-hidden">
      <li
        *ngFor="let item of AccordionsList; let i = index"
        class="last:border-b-0 border-b-2 border-gray-card"
        [class.active]="item.active">
        <div
          class="cursor-pointer text-gray-dark text-h3-subheading pt-6 pb-0.5 p-5">
          <div
            class="relative text-primary-additional flex flex-row uppercase justify-start">
            <h3
              class="w-full text-primary-base no-wrap-text"
              [ngClass]="{ 'text-accent-base': item.active }"
              routerLink="{{ item.link }}"
              [queryParams]="{ subscribe: 'monthly' }">
              {{ item.name }}
            </h3>
            <div *ngIf="item.subMenu" class="w-full" (click)="toggle(i)">
              <svg
                width="22px"
                height="13px"
                icon="arrow-bottom"
                iconCollection="button-icons-sprite"
                [ngClass]="{ 'rotate-180': item.active }"
                class="absolute top-2 right-0 transition-all duration-700 ease fill-accent-base"></svg>
            </div>
          </div>
          <div
            class="text-smaller-main mt-5 overflow-hidden transition-[height] duration-1000 ease"
            #innerText
            [style.height.px]="item.active ? innerText.scrollHeight : 0">
            <ng-container *ngIf="AccordionsList[i].subMenu">
              <div
                *ngFor="let subItem of AccordionsList[i].subMenu"
                class="mb-5">
                <div
                  class="border-b-2 border-primary-base text-main-text-bold text-primary-base hover:text-secondary-base hover:border-secondary-base my-2"
                  routerLink="{{ subItem.link }}"
                  (click)="togglePoint(i)"
                  appScrollToSectionAfterLoadData
                  scrollToSection="{{ subItem.titleLinkId }}"
                  [queryParams]="subItem.queryParams">
                  {{ subItem.title }}
                </div>
                <ng-container *ngIf="subItem.subMenuItems">
                  <div
                    *ngFor="let SubMenuItems of subItem.subMenuItems"
                    (click)="togglePoint(i)"
                    class="py-2 text-smaller-main hover:text-secondary-base"
                    routerLink="{{ SubMenuItems.link }}"
                    appScrollToSectionAfterLoadData
                    scrollToSection="{{ SubMenuItems.id }}">
                    {{ SubMenuItems.title }}
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngSwitchCase="vacancy">
    <ul class="accordion {{ classList }}">
      <li
        *ngFor="let item of AccordionsList; let i = index"
        class="first:border-t-2 first:border-gray-stroke"
        [class.active]="item.active">
        <div
          class="text-gray-dark text-h3-subheading border-b-2 border-gray-stroke pb-0.5 pt-6 pl-14 xl:pl-18 pr-2 xl:pr-0"
          [ngClass]="{ 'pb-[25px]': item.active }">
          <div class="relative cursor-pointer" (click)="toggle(i)">
            <svg
              width="22px"
              height="13px"
              icon="arrow-bottom"
              iconCollection="button-icons-sprite"
              [ngClass]="{ 'rotate-180': item.active }"
              class="absolute top-2 left-[-40px] transition-all duration-700 ease fill-accent-base"></svg>
            <h3>{{ item.title }}</h3>
          </div>
          <div
            class="text-main-text mt-5 ml-[-2.5rem] overflow-hidden pl-2.5 transition-[height] duration-1000 ease"
            #innerText
            [style.height.px]="item.active ? innerText.scrollHeight : 0">
            <ng-container *ngIf="item.text">
              <p class="pl-2.5 py-5" *ngFor="let text of item.text">
                {{ text }}
              </p>
            </ng-container>
            <ng-container *ngIf="item.string">
              <p class="pl-2.5 mb-5">{{ item.string }}</p>
            </ng-container>
            <div class="ml-[18px] xl:w-[1060px]">
              <div class="">{{ item.description }}</div>
              <div class="text-main-text-bold mt-[26px]">
                {{ item.offerTitle }}
              </div>
              <ul class="">
                <li
                  class="list-disc pb-[10px] ml-[30px]"
                  *ngFor="let SubMenuItems of item.offerList">
                  <h3>{{ SubMenuItems }}</h3>
                </li>
              </ul>
              <div class="text-main-text-bold mt-[20px]">
                {{ item.requirementsTitle }}
              </div>
              <ul>
                <li
                  class="list-disc pb-[10px] ml-[30px]"
                  *ngFor="let SubMenuItems of item.requirementsList">
                  <h3>{{ SubMenuItems }}</h3>
                </li>
              </ul>
              <div class="mt-[16px] text-main-text-bold">
                {{ item.slogan }}
              </div>
              <div
                class="text-main-text mt-[40px] flex flex-col md:flex-row gap-[10px]">
                {{ item.applyText }}
                <div class="flex gap-[10px]">
                  <ng-container *ngIf="!item.isCopy">
                    <button
                      class="w-6 h-6 md:w-7 md:h-7"
                      (click)="changeIcon(item)"
                      cdkCopyToClipboard="{{ item.email }}"
                      aria-label="copyEmail">
                      <svg
                        width="20px"
                        height="24px"
                        icon="copy"
                        iconCollection="button-icons-sprite"
                        class="fill-accent-base hover:fill-secondary-base active:fill-secondary-additional w-full h-full"></svg>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="item.isCopy">
                    <button
                      class="w-6 h-6 md:w-7 md:h-7 relative"
                      cdkCopyToClipboard="{{ item.email }}"
                      aria-label="copyEmail">
                      <svg
                        width="20px"
                        height="24px"
                        icon="checkBox"
                        iconCollection="button-icons-sprite"
                        class="fill-secondary-base w-full h-full"></svg>
                      <div
                        id="pop-over"
                        class="bg-gray-white absolute -translate-x-[35px] -translate-y-[50px] text-gray-medium w-28.5 h-6 rounded-30 text-details flex justify-center items-center z-10">
                        Скопійовано
                      </div>
                    </button>
                  </ng-container>
                  <span
                    class="text-accent-base cursor-pointer"
                    (click)="changeIcon(item)"
                    cdkCopyToClipboard="{{ item.email }}"
                    >{{ item.email }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
</ng-container>
