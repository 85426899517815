import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { emailValidator } from 'src/app/components/forms/validators/email-validator';
import { EmailService } from 'src/app/services/api/email.service';

interface IUnsubscribeForm {
  userEmail: FormControl<string>;
}

export interface IUnsubscribeFormData {
  userEmail: string | undefined;
}
@Component({
  selector: 'app-unsubscribe-page',
  templateUrl: './unsubscribe-page.component.html',
  styleUrls: ['./unsubscribe-page.component.css'],
})
export class UnsubscribePageComponent implements OnInit, OnDestroy {
  unsubscribeForm!: FormGroup<IUnsubscribeForm>;
  isShowSuccessMessage: boolean = false;
  destroy$!: Subscription;

  constructor(private emailService: EmailService) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    if (this.destroy$) {
      this.destroy$.unsubscribe();
    }
  }

  public onSubmit(): void {
    const formData: IUnsubscribeFormData = {
      userEmail: this.unsubscribeForm.value.userEmail?.trim(),
    };

    if (this.unsubscribeForm.valid) {
      this.destroy$ = this.emailService.unsubscribe(formData).subscribe({
        next: () => (this.isShowSuccessMessage = true),
      });
    } else {
      this.unsubscribeForm.controls.userEmail.markAsTouched();
    }
  }

  private initForm(): void {
    this.unsubscribeForm = new FormGroup<IUnsubscribeForm>({
      userEmail: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, emailValidator],
      }),
    });
  }
}
