import { Component, OnDestroy, OnInit } from '@angular/core';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import {
  TextContentModel,
  TextContentService,
} from '../../../../services/api/text-content.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.css'],
})
export class StudyComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  pageName: string = 'about-us/programs/education';
  isHovered: boolean = false;
  contactsLink = PAGE_ROUTES.contacts;
  subs: Subscription = new Subscription();
  pageData: TextContentModel[] = [];

  constructor(private textContentService: TextContentService) {}

  protected links: { readonly id: string; readonly title: string }[] = [];

  ngOnInit(): void {
    const sub = this.textContentService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.links = this.pageData.map(el => ({
          id: el.key,
          title: this.firsLetterUpper(el.title),
        }));
        this.isLoading = false;
      });
    this.subs.add(sub);
  }

  firsLetterUpper(str: string) {
    const firstLetter = str.charAt(0).toUpperCase();
    const lowerStr = str.toLowerCase().slice(1);
    return firstLetter + lowerStr;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
