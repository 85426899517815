import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SubscribeItem } from 'src/app/models/subscribeItem.interface';
import { subscribeArray } from 'src/mockData/subscribeArray';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';

@Component({
  selector: 'app-subscribe-help',
  templateUrl: './subscribe-help.component.html',
  styleUrls: ['./subscribe-help.component.css'],
})
export class SubscribeHelpComponent implements OnInit {
  subscribeArray: SubscribeItem[] = subscribeArray;
  bankChecked: boolean = false;
  form!: FormGroup;
  donateInstruction: any = {
    patreon: '/assets/pdf/donation-instruction/instructionPatreon.pdf',
    monobank: '/assets/pdf/donation-instruction/instructionMonobank.pdf',
    privatbank: '/assets/pdf/donation-instruction/instructionPrivat24.pdf',
  };
  linkToUnderConstructionPage = `../../${PAGE_ROUTES.pageInDevelopment}`;
  constructor() {}

  ngOnInit(): void {
    this.form = new FormGroup({
      bankName: new FormControl('', Validators.required),
    });
  }

  onSubmit() {
    const key = this.form.controls['bankName'].value;
    window.open(donateServicesName[key], '_blank');
    this.form.reset();
    this.bankChecked = false;
  }
}

const donateServicesName: any = {
  patreon: 'https://patreon.com/Nezabutni',
  monobank: 'https://send.monobank.ua/jar/9EY7sSoYFp',
  privatbank:
    'https://next.privat24.ua/regular-payments/add/C/form/%7B%22companyId%22:%224389195%22,%22query%22:%22%D0%BD%D0%B5%D0%B7%D0%B0%D0%B1%D1%83%D1%82%D0%BD%D1%96%22%7D',
};
