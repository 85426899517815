<main class="pb-1">
  <section class="mb-30">
    <app-page-banner-item [pageName]="pageName"></app-page-banner-item>
  </section>

  <section>
    <app-table-of-content [links]="links"></app-table-of-content>
  </section>

  <!-- Хто такі “Незабутні” -->
  <section id="who_are">
    <div class="content-container pt-30 mb-30">
      <h2 class="text-h2-heading uppercase text-primary-base mb-5">
        Хто такі “Незабутні”
      </h2>

      <p class="text-main-text text-gray-dark mb-7">
        До 2025 року 1 мільйон людей у Великій Британії будуть жити з цим
        захворюванням, і ще багато мільйонів опікунів, партнерів, сімей і друзів
        постраждають.
      </p>
      <p class="text-main-text text-gray-dark mb-10">
        Відсутність лікування та лотереї поштових індексів для догляду означає,
        що люди з деменцією та їхні родини не отримують необхідної підтримки,
        коли вони її потребують.
      </p>

      <div class="flex items-center">
        <span class="block w-2.5 h-24 bg-primary-base rounded-l-10"></span>
        <p class="w-286.25 ml-5 text-h3-subheading text-gray-dark">
          Ми не заспокоїмося, доки люди з деменцією та особи, які за ними
          доглядають, не будуть жити більш повноцінним і менш страшним життям,
          вільним від стигми та нерівності.
        </p>
      </div>

      <p class="text-main-text text-gray-dark mt-10">
        Наші прихильники гарантують, що люди, які сьогодні живуть із деменцією,
        отримують підтримку, на яку вони заслуговують, що їхні права захищені та
        зміцнені шляхом проведення кампаній, і що вони можуть дивитися в світле
        майбутнє завдяки інвестиціям у дослідження.
      </p>
    </div>
  </section>

  <!-- Наша історія -->
  <section id="our_history" class="py-30 bg-gray-card">
    <div class="content-container">
      <h2 class="text-h2-heading uppercase text-primary-base mb-5">
        Наша історія
      </h2>

      <div class="flex">
        <div class="flex flex-col">
          <p class="text-main-text text-gray-dark mb-7">
            Асоціація Альцгеймера була заснована в 1980 році групою сімейних
            опікунів і окремих осіб, які визнали потребу в організації, яка
            об’єднала б опікунів, надала підтримку тим, хто зіткнувся з хворобою
            Альцгеймера, і просувала дослідження цієї хвороби.
          </p>
          <p class="text-main-text text-gray-dark mb-7">
            Джером Х. СтоунДжером Х. Стоун, справжній провидець у русі
            Альцгеймера, був нашим президентом-засновником. Коли в 1970 році
            його дружині, Евелін, діагностували хворобу Альцгеймера, інформація
            про хворобу Альцгеймера — навіть серед медичної спільноти — була
            обмежена. З драйвом і рішучістю містер Стоун впорався з хворобою,
            яка була прихована мовчанням, і допоміг створити глобальну розмову.
          </p>
          <p class="text-main-text text-gray-dark mb-7">
            Сьогодні Асоціація охоплює мільйони людей, уражених хворобою
            Альцгеймера та іншими видами деменції. Ми є провідною добровільною
            медичною організацією з лікування, підтримки та досліджень хвороби
            Альцгеймера.
          </p>
          <p class="text-main-text text-gray-dark">
            Разом ми можемо покінчити з хворобою Альцгеймера. Пожертвуйте
            сьогодні.
          </p>
        </div>
        <img
          class="ml-10"
          src="../../../../../assets/images/our_idea/our_history_img.png"
          alt="" />
      </div>
    </div>
  </section>

  <!-- Наші цінності -->
  <section id="our_values" class="py-30">
    <div class="content-container">
      <h2 class="text-h2-heading uppercase text-primary-base mb-10">
        Наші цінності
      </h2>

      <div class="flex">
        <div class="flex flex-col">
          <p class="text-main-text text-gray-dark mb-7">
            Люди, уражені деменцією, заслуговують на те, щоб мати вибір щодо
            того, як найкраще керувати своїм станом.
          </p>

          <ul class="text-main-text text-gray-dark list-disc pl-7 mb-10">
            <li class="mb-2.5">
              Люди, уражені деменцією, заслуговують на те, щоб мати вибір щодо
              того, як найкраще керувати своїм станом.
            </li>
            <li class="mb-2.5">
              Люди, уражені деменцією, заслуговують на те, щоб мати вибір щодо
              того, як найкраще керувати своїм станом.
            </li>
            <li class="mb-2.5">
              Люди, уражені деменцією, заслуговують на те, щоб мати вибір щодо
              того, як найкраще керувати своїм станом.
            </li>
            <li>
              Люди, уражені деменцією, заслуговують на те, щоб мати вибір щодо
              того, як найкраще керувати своїм станом.
            </li>
          </ul>

          <div class="flex items-center">
            <span class="block w-2.5 h-31 bg-primary-base rounded-l-10"></span>
            <p class="w-157.5 ml-5 text-h3-subheading text-gray-dark">
              Ми не заспокоїмося, доки люди з деменцією та особи, які за ними
              доглядають, не будуть жити більш повноцінним і менш страшним
              життям, вільним від стигми та нерівності.
            </p>
          </div>
        </div>
        <img
          class="ml-10"
          src="../../../../../assets/images/our_idea/our_values.png"
          alt="" />
      </div>
    </div>
  </section>

  <!-- Наш символ -->
  <section id="our_symbol" class="bg-primary-base">
    <div class="flex flex-col items-center pt-30 mb-18.75 h-266.75">
      <div class="content-container flex justify-between">
        <h2 class="text-h2-heading uppercase text-gray-white">Наш символ</h2>

        <div class="w-195">
          <p class="text-main-text text-gray-white mb-7">
            Незабудка є міжнародним символом боротьби з деменцією.
          </p>
          <p class="text-main-text text-gray-white mb-7">
            Градієнт, що поступово зникає, нагадує про серйозність проблеми
            деменції. Якщо не помічати проблему, люди будуть просто зникати з
            нашого життя.
          </p>
          <p class="text-main-text text-gray-white">
            Фіолетовий і рожевий — основні кольори айдентики. Яскраві кольори
            викликають ассоціації з надією.
          </p>
        </div>
      </div>
      <iframe
        class="mt-20 w-295 h-166.25"
        [src]="safeURL"
        frameborder="0"
        allowfullscreen></iframe>
    </div>

    <div class="bg-primary-base pb-30">
      <div class="content-container h-154 bg-gray-white rounded-30 px-25 py-20">
        <h3 class="text-h3-subheading text-primary-base mb-5">
          Детальніше про символ
        </h3>
        <p class="text-main-text text-gray-dark mb-7">
          Наша візія - кожен вміє попередити, жити з та бути дружнім до
          деменції.
        </p>
        <p class="text-main-text text-gray-dark">
          Наша мета - створювати дружнє до деменції середовище, надаючи
          підтримку людям з деменцією та їх родичам, інформуючи суспільство та
          об’єднуючи однодумців. Ми хочемо об'єднати зусилля всіх зацікавлених
          сторін у боротьбі із цією серйозною медичною і соціальною проблемою.
        </p>

        <div id="about_name" class="pt-20">
          <h3 class="text-h3-subheading text-primary-base mb-5">
            Чому “Незабутні”
          </h3>
          <p class="text-main-text text-gray-dark mb-7">
            Наша візія - кожен вміє попередити, жити з та бути дружнім до
            деменції.
          </p>
          <p class="text-main-text text-gray-dark">
            Наша мета - створювати дружнє до деменції середовище, надаючи
            підтримку людям з деменцією та їх родичам, інформуючи суспільство та
            об’єднуючи однодумців. Ми хочемо об'єднати зусилля всіх зацікавлених
            сторін у боротьбі із цією серйозною медичною і соціальною проблемою.
          </p>
        </div>
      </div>
    </div>
  </section>

  <app-help-block [type]="'secondary'"></app-help-block>
</main>
