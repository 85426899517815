import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fourth-main-slide',
  standalone: false,
  templateUrl: './fourth-main-slide.component.html',
  styleUrl: './fourth-main-slide.component.css',
})
export class FourthMainSlideComponent {
  @Input()
  text!: string;
  @Input()
  title!: string;
}
