import { Component, OnInit } from '@angular/core';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import {
  TextContentModel,
  TextContentService,
} from '../../services/api/text-content.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css'],
})
export class MainPageComponent implements OnInit {
  getInvolvedLink = PAGE_ROUTES.join;
  isLoaded: boolean = false;
  aboutDementiaTextBlock!: TextContentModel;
  aboutDementiaTabBlock!: TextContentModel[];
  preventionTextBlock!: TextContentModel;
  preventionCardsBlock!: TextContentModel[];
  aboutFondTextBlock!: TextContentModel[];
  ourAchievementsTextBlock!: TextContentModel;
  ourAchievementsCardsBlock!: TextContentModel[];
  textContentData: TextContentModel[] = [];
  // fourthBannerTitle =
  //   'ЗАПРОШУЄМО ДО УЧАСТІ <br>У ВСЕСВІТНЬОМУ ДОСЛІДЖЕННІ<br> "СТАВЛЕННЯ ДО ДЕМЕНЦІЇ"';

  constructor(private readonly contentService: TextContentService) {}

  ngOnInit(): void {
    this.contentService.getTextsContentByPageName('main').subscribe(res => {
      if (res) {
        this.aboutDementiaTabBlock = res
          .filter(obj => obj.groupType === 'AboutDementia')
          .sort((a, b) => a.id - b.id);
        this.aboutDementiaTextBlock = res.find(
          el => el.key === 'main_about-dementia',
        )!;
        this.preventionTextBlock = res.find(
          el => el.key === 'main_prevention',
        )!;
        this.preventionCardsBlock = res
          .filter(obj => obj.groupType === 'Prevention')
          .sort((a, b) => a.id - b.id);
        this.aboutFondTextBlock = res
          .filter(obj => obj.groupType === 'AboutFondBlock')
          .sort((a, b) => a.id - b.id);
        this.ourAchievementsTextBlock = res.find(
          el => el.key === 'main_our-achievements',
        )!;
        this.ourAchievementsCardsBlock = res.filter(
          obj => obj.groupType === 'OurAchievements',
        );

        this.textContentData = res;
        this.isLoaded = true;
      }
    });
  }
}
