import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface TextContentModel {
  id: number;
  pageName: string;
  key: string;
  title: string;
  bodyText: string;
  buttonURL: string;
  imageURL: string;
  groupType: string;
  File?: string | File;
  orderId?: number;
  buttonName: string;
}

@Injectable({
  providedIn: 'root',
})
export class TextContentService {
  private url: string = environment.apiUrl + '/Text/GetTextsByPageName';
  adminUrl: string = environment.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getTextsContentByPageName(pageName: string) {
    return this.http.get<TextContentModel[]>(
      this.url + `?pageName=${encodeURIComponent(pageName)}`,
    );
  }

  updateTextContent(data: any) {
    const formData = new FormData();
    for (let str in data) {
      formData.append(str, data[str]);
    }
    return this.http.put(this.adminUrl + 'UpdateText', formData);
  }

  createTextContent(data: any) {
    const formData = new FormData();
    for (let str in data) {
      formData.append(str, data[str]);
    }
    return this.http.post(this.adminUrl + 'AddText', formData);
  }

  deleteTextContent(data: { id: number; imageUrl?: string }) {
    return this.http.delete(
      this.adminUrl +
        (data.imageUrl
          ? `DeleteText?id=${data.id}&imageURL=${data.imageUrl}`
          : `DeleteText?id=${data.id}`),
    );
  }

  updateTextContentOrder(data: any) {
    return this.http.put(this.adminUrl + 'UpdateTextOrder', data);
  }
}
