export const requisitesPaid = [
  [
    'main',
    { name: 'UAH', value: 'main' },
    { name: 'Отримувач', value: 'БФ “Незабутні”' },
    { name: 'ЄДРПОУ', value: '44228139' },
    { name: 'IBAN', value: 'UA733052990000026003006223278' },
    { name: 'Банк', value: 'АТ КБ «ПРИВАТБАНК»' },
    { name: 'Призначення платежу', value: 'Благодійний внесок' },
  ],
  [
    'main',
    { name: 'EUR', value: 'main' },
    { name: 'Account', value: 'Nezabutni Charitable Foundation' },
    { name: 'IBAN', value: 'UA693052990000026009026229881' },
    {
      name: 'Bank',
      value: 'JSC CB “PrivatBank”, 1D Hrushevskogo str., Kyiv, 01001, Ukraine',
    },
    { name: 'SWIFT', value: 'PBANUA2X' },
    {
      name: 'Address',
      value: '13 Nazarivska street, office 6, 01032 Kyiv, Ukraine',
    },
    { name: 'Purpose of payment', value: 'Charitable donation' },
  ],
  [
    'main',
    { name: 'USD', value: 'main' },
    { name: 'Account', value: 'Nezabutni Charitable Foundation' },
    { name: 'IBAN', value: 'UA603052990000026002036228556' },
    {
      name: 'Bank',
      value: 'JSC CB “PrivatBank”, 1D Hrushevskogo str., Kyiv, 01001, Ukraine',
    },
    { name: 'SWIFT', value: 'PBANUA2X' },
    {
      name: 'Address',
      value: '13 Nazarivska street, office 6, 01032 Kyiv, Ukraine',
    },
    { name: 'Purpose of payment', value: 'Charitable donation' },
  ],
  [
    'main',
    { name: 'GBP', value: 'main' },
    { name: 'Account', value: 'Nezabutni Charitable Foundation' },
    { name: 'IBAN', value: 'UA403052990000026004046221303' },
    {
      name: 'Bank',
      value: 'JSC CB “PrivatBank”, 1D Hrushevskogo str., Kyiv, 01001, Ukraine',
    },
    { name: 'SWIFT', value: 'PBANUA2X' },
    {
      name: 'Address',
      value: '13 Nazarivska street, office 6, 01032 Kyiv, Ukraine',
    },
    { name: 'Purpose of payment', value: 'Charitable donation' },
  ],
];
